import { useEffect } from 'react'
import { NextPage } from 'next'
import styled from 'styled-components'

import type { ErrorPageProps } from '~/types/page'

import { Anchor, Image, PageTitle } from '~/components/atoms'
import { StandardTemplate } from '~/components/templates'
import { PageProvider } from '~/contexts'
import { Analytics, setAdTargets } from '~/util'

//----- Styling -----//

const Wrap = styled.div`
	text-align: center;
`

const Paragraph = styled.p`
	@media (min-width: ${(p) => p.theme.responsive.breakpoints.lg}) {
		font-size: 1.8em;
		display: inline;

		&::after {
			content: ' ';
		}
	}
`

//----- Component -----//

export const ErrorPage: NextPage<ErrorPageProps> = (props) => {
	const { errorStatus } = props
	const pageConfig = { announce: {}, title: `${errorStatus} Error` }

	const errorCalloutUrl =
		'/planet-earth/uncovering-the-secrets-of-mammoth-island'

	// NextMillennium ad targeting code
	useEffect(() => setAdTargets(null, null), [])

	useEffect(() => {
		Analytics.getInstance()
			.setLoginStatus()
			.setCategory(null)
			.pageview(`/error/${errorStatus}`)
	})

	return (
		<PageProvider pageConfig={pageConfig}>
			<StandardTemplate useContainer>
				<Wrap>
					{errorStatus === 404 && (
						<Image
							src="/assets/error/hero404.jpg"
							alt="Wooly Mammoth"
							maxWidth={650}
							eager
						/>
					)}
					<PageTitle>
						There&apos;s been a{' '}
						<Anchor color={'accent'} to={errorCalloutUrl}>
							Mammoth
						</Anchor>{' '}
						Mistake.
					</PageTitle>
					<Paragraph>
						Like the mammoth, this page seems to be extinct.
					</Paragraph>
					<Paragraph>
						But while we&apos;re on the topic, come explore the{' '}
						<Anchor color={'accent'} to={errorCalloutUrl}>
							secrets of mammoth island
						</Anchor>{' '}
						with Discover.
					</Paragraph>
					<Paragraph>
						Or we can always take you{' '}
						<Anchor color={'accent'} to={'/'}>
							home
						</Anchor>
						.
					</Paragraph>
				</Wrap>
			</StandardTemplate>
		</PageProvider>
	)
}
