import facebook from './facebook.png'
import google from './google.png'
import instagram from './instagram.png'
import rss from './rss.png'
import soundcloud from './soundcloud.png'
import twitter from './twitter.png'
import youtube from './youtube.png'

interface ImageList {
	facebook: any
	google: any
	instagram: any
	rss: any
	soundcloud: any
	twitter: any
	youtube: any
	[key: string]: any
}

export const socialIcons: ImageList = {
	facebook,
	google,
	instagram,
	rss,
	soundcloud,
	twitter,
	youtube
}
