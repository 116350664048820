import { createContext, useContext } from 'react'

interface RichTextState {
	template?: string
}

const RichTextContext = createContext<RichTextState | undefined>(undefined)

//----- Consumers -----//

export const useRichTextContext = (): RichTextState =>
	useContext(RichTextContext) as RichTextState
export const RichTextConsumer = RichTextContext.Consumer

//----- Provider -----//

export const RichTextProvider = RichTextContext.Provider
