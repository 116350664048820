import { ReactElement, ReactNode, useRef, useEffect } from 'react'

import { LoadingSpinner_Ring } from '~/components/atoms'
import { ArticleGrid } from '~/components/organisms'
import { SiteConfig } from '~/config'
import type { ArticleSummaryData } from '~/types/content'

//----- Definitions -----//
type AdUnit = keyof typeof SiteConfig.ads.unit
const ADUNITS: AdUnit[] = ['rotationMedRec1', 'rotationMedRec2']

//----- Component -----//

interface PaginatedGridProps {
	articlePages: {
		[key: string]: ArticleSummaryData[] | false
	}
	setCurrentPage: (pageNum: number) => void
}

/**
 * Handles scroll events
 */
const GridSection = (props: {
	page: number
	setCurrentPage: (pageNum: number) => void
	children: ReactNode
}) => {
	const sectionRef = useRef<HTMLDivElement>(null)

	let lastRun = new Date().getTime()

	useEffect(() => {
		const handleScroll = () => {
			const now = new Date().getTime()
			if (!window || now <= lastRun + 100) return

			lastRun = now

			const pathSegments = window.location.pathname.split('/')
			const currentPage =
				pathSegments.length >= 3 ? parseInt(pathSegments[3]) : 1

			// No need to trigger a change if we're already on the page
			if (props.page === currentPage) return

			const rect = sectionRef.current?.getBoundingClientRect()

			if (rect && rect.top < 100 && rect.bottom > 100)
				props.setCurrentPage(props.page)
		}

		window.addEventListener('scroll', handleScroll)

		return () => window.removeEventListener('scroll', handleScroll)
	})

	return <div ref={sectionRef}>{props.children}</div>
}

export const PaginatedGrid = (props: PaginatedGridProps): ReactElement => {
	const pageNums = Object.keys(props.articlePages)
		.map((a) => parseInt(a))
		.sort((a, b) => a - b)

	return (
		<>
			{pageNums.map((page, i) => {
				const articles = props.articlePages[page]

				if (articles === false)
					return (
						<div key={i} style={{ margin: '2em 0', textAlign: 'center' }}>
							<LoadingSpinner_Ring />
						</div>
					)

				return (
					<GridSection
						key={i}
						page={page}
						setCurrentPage={props.setCurrentPage}
					>
						<ArticleGrid
							articles={articles}
							showAdAfter={6}
							adUnitName={ADUNITS[i % 2]}
						/>
					</GridSection>
				)
			})}
		</>
	)
}
