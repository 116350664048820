import { ReactElement } from 'react'
import styled, { css } from 'styled-components'

import { Anchor_Internal } from '~/components/atoms'
import type { CategoryData } from '~/types/content'
import { getPath } from '~/util'

//----- Styling -----//

const CategoryClass = css`
	display: inline-block;
	margin-top: 1em;

	font-size: 0.75em;
	font-weight: ${(p) => p.theme.font.sans.weight.bold};

	text-transform: uppercase;
	color: ${(p) => p.theme.color.gray[46]};
`

const CategoryAnchor = styled(Anchor_Internal)`
	border-bottom: 2px solid ${(p) => p.theme.color.gray[46]};
	transition: color 300ms ease-in-out, border-bottom 300ms ease-in-out;

	&:hover {
		color: ${(p) => p.theme.color.gray[0]};
		border-bottom: 2px solid ${(p) => p.theme.color.gray[0]};
		text-decoration: none;
	}

	${CategoryClass}
`
const CategorySpan = styled.span`
	${CategoryClass}
`

//----- Component -----//

export const CategoryLink = (props: {
	category?: CategoryData
	template?: string
}): ReactElement | null => {
	if (!props.category) return null

	const categoryPath = getPath.category(props.category.slug)

	return props.template === 'Sponsored' ? (
		<CategorySpan>{props.category.name}</CategorySpan>
	) : (
		<CategoryAnchor to={categoryPath}>{props.category.name}</CategoryAnchor>
	)
}
