import { ReactElement } from 'react'
import styled from 'styled-components'

import type { AuthorData, BlogData } from '~/types/content'

import { Author, Blog, PublicationDate } from './atoms'

//----- Styling -----//

const StyledByLine = styled.div`
	margin-top: 1.325em;
	font-size: 0.75em;
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
`

//----- Component -----//

interface ByLineProps {
	authors?: AuthorData[]
	blog?: BlogData
	publicationDate?: string
}

export const ByLine = (props: ByLineProps): ReactElement | null => {
	const { authors, blog, publicationDate } = props

	if (!authors?.length) return null

	return (
		<StyledByLine>
			<Blog blog={blog} />
			<span>By </span>
			{authors.map((author, i) => (
				<Author key={i} i={i} total={authors.length} author={author} />
			))}
			<PublicationDate date={publicationDate} />
		</StyledByLine>
	)
}
