import { ReactElement } from 'react'

import type { ArticleData } from '~/types/content'

import { MetaTags, StructuredData } from './atoms'

//----- Component -----//

export const ArticleMeta = (props: {
	article: ArticleData
	url: string
}): ReactElement => {
	const { article, url } = props

	const basicInfo = {
		url,
		title: article.title,
		description: article.subtitle || article.title,
		imageUrl: article.image?.url,
		author: article.refs.authors ? article.refs.authors[0] : undefined
	}

	return (
		<>
			<MetaTags {...basicInfo} imageAlt={article.image?.description} />
			<StructuredData
				{...basicInfo}
				datePublished={article.meta.publicationDate}
				dateModified={article.meta.modifiedDate}
			/>
		</>
	)
}
