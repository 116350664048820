import { ReactElement } from 'react'
import Head from 'next/head'

import type { AuthorData } from '~/types/content'
import { SiteConfig } from '~/config'

//----- Component -----//

interface MetaTagsProps {
	url: string
	title?: string
	description?: string
	imageUrl?: string
	imageAlt?: string
	author?: AuthorData
}

export const MetaTags = (props: MetaTagsProps): ReactElement => {
	const imageUrl = props.imageUrl?.startsWith('//')
		? `https:${props.imageUrl}`
		: props.imageUrl

	return (
		<Head>
			<meta name="description" content={props.description} />

			<meta property="og:title" content={props.title} />
			<meta property="og:description" content={props.description} />
			<meta property="og:image" content={imageUrl} />
			<meta property="og:url" content={props.url} />
			<meta property="og:site_name" content={SiteConfig.siteInfo.title} />

			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:site" content={SiteConfig.social.twitterUser} />
			<meta name="twitter:creator" content={props.author?.twitterUsername} />
			<meta name="twitter:title" content={props.title} />
			<meta name="twitter:description" content={props.description} />
			<meta name="twitter:image:src" content={imageUrl} />
			<meta name="twitter:image:alt" content={props.imageAlt} />

			<meta property="fb:app_id" content={SiteConfig.social.facebookAppID} />
		</Head>
	)
}
