import { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'

import { Modal } from '~/components/molecules'
import { useAppContext, usePaywallContext } from '~/contexts'

import { ModalContent, ModalFooter, ModalHeader } from './molecules'

//----- Styling -----//

const Wrapper = styled.div`
	text-align: center;
	text-transform: uppercase;
`

//----- Component -----//

export const PaywallPrompt = (): ReactElement | null => {
	const app = useAppContext()
	const paywall = usePaywallContext()
	const [fadeModal, setModalFade] = useState(false)

	useEffect(() => {
		app.setPaywallPromptOpen(!paywall.hasAccess)
	}, [paywall.hasAccess])

	useEffect(() => {
		const subModalIsOpen =
			app.loginModalIsOpen ||
			app.registerModalIsOpen ||
			app.accessCodeModalIsOpen

		setModalFade(subModalIsOpen)
	}, [app.loginModalIsOpen, app.registerModalIsOpen, app.accessCodeModalIsOpen])

	return (
		<Modal
			isOpen={!paywall.hasAccess}
			unclosable
			inverted
			style={fadeModal ? { filter: 'brightness(20%)' } : {}}
		>
			<Wrapper>
				<ModalHeader />
				<ModalContent />
				<ModalFooter />
			</Wrapper>
		</Modal>
	)
}
