import { ReactElement } from 'react'
import styled from 'styled-components'

import type { RichTextContent } from '~/types/content'
import { ContentAsReact } from '../util'

//----- Styling -----//

const H3 = styled.h3`
	font-family: ${(p) => p.theme.font.serif.family};
	font-weight: ${(p) => p.theme.font.serif.weight.regular};
	font-size: 1.3em;
`

//----- Component -----//

export const Heading3 = (props: RichTextContent): ReactElement => (
	<H3>
		<ContentAsReact content={props.content} />
	</H3>
)
