import { ReactElement, useEffect, useState } from 'react'

import { ModalTitle } from '~/components/atoms'
import { Modal } from '~/components/molecules'
import { useAppContext, useUserContext } from '~/contexts'

import {
	FailedView,
	LoadingView,
	NewsletterView,
	RegisterView,
	RejectedView,
	SuccessView
} from './molecules'

//----- Constants & Types -----//

enum view {
	REGISTER,
	NEWSLETTER,
	LOADING,
	REJECTED,
	FAILED,
	SUCCESS
}

//----- Component -----//

export const RegisterModal = (): ReactElement => {
	const app = useAppContext()
	const user = useUserContext()

	const [activeView, setActiveView] = useState(view.REGISTER)

	// Reset view if the user logs out
	useEffect(() => {
		if (!user.isLoggedIn) setActiveView(view.REGISTER)
	}, [user.isLoggedIn])

	// Reset the view if the user closes the dialog
	useEffect(() => {
		setTimeout(() => {
			const useView = user.isLoggedIn ? view.SUCCESS : view.REGISTER
			if (!app.registerModalIsOpen) setActiveView(useView)
		}, 1500)
	}, [app.registerModalIsOpen])

	const showRegister = () => setActiveView(view.REGISTER)
	const showNewsletter = () => setActiveView(view.NEWSLETTER)
	const showLoading = () => setActiveView(view.LOADING)
	const showResult = (result: 'success' | 'rejected' | 'failed') => {
		if (result === 'success') setActiveView(view.SUCCESS)
		else if (result === 'rejected') setActiveView(view.REJECTED)
		else setActiveView(view.FAILED)
	}

	function getView(viewType: view) {
		switch (viewType) {
			case view.REGISTER:
				return <RegisterView showNext={showNewsletter} />
			case view.NEWSLETTER:
				return (
					<NewsletterView showNext={showLoading} showPrevious={showRegister} />
				)
			case view.LOADING:
				return <LoadingView showNext={showResult} />
			case view.SUCCESS:
				return <SuccessView />
			case view.REJECTED:
				return <RejectedView tryAgain={showRegister} />
			case view.FAILED:
			default:
				return <FailedView tryAgain={showRegister} />
		}
	}

	return (
		<Modal isOpen={app.registerModalIsOpen}>
			<ModalTitle>Register for an Account</ModalTitle>
			{getView(activeView)}
		</Modal>
	)
}
