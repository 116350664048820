import React, { ReactElement } from 'react'
import styled from 'styled-components'

import { Anchor, Image } from '~/components/atoms'
import { SiteConfig } from '~/config'
import { globalAssets, socialIcons } from '~/static/assets'

//----- Styling -----//

const SocialWrap = styled.div`
	text-align: center;
`

const SocialLinks = styled.div`
	display: flex;
	justify-content: center;
	align-content: stretch;
	align-items: center;
	flex-wrap: wrap;
	margin: 0 0 64px;

	a {
		display: inline-block;
		max-width: 100px;
		margin-top: 32px;
		flex: 1 1 50%;
	}

	/* TODO: This should be handled by the images, not CSS */
	img {
		border-radius: 3px;
	}

	@media (min-width: 430px) {
		margin: 0 32px 64px;

		a {
			flex-basis: 50%;
		}
	}

	@media (min-width: 520px) {
		a {
			flex-basis: 33%;
			max-width: 33%;
		}
	}

	@media (min-width: 620px) {
		margin: 0 auto 64px;
		max-width: 500px;

		a {
			flex-basis: auto;
		}
	}
`

//----- Component -----//

export const Social = (): ReactElement => {
	const { socialList } = SiteConfig.footerInfo
	const { splashText } = SiteConfig.siteInfo

	return (
		<SocialWrap>
			<Image
				src={globalAssets.footerSplash}
				alt={splashText}
				maxWidth={173}
				maxHeight={146}
			/>

			<SocialLinks>
				{socialList.map((link, i) => (
					<Anchor key={i} to={link.url}>
						<Image
							src={socialIcons[link.image]}
							alt={link.altName}
							maxWidth={50}
							maxHeight={50}
							eager
						/>
					</Anchor>
				))}
			</SocialLinks>
		</SocialWrap>
	)
}
