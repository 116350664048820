import { ReactElement } from 'react'
import styled from 'styled-components'
import {
	FacebookShareButton,
	FacebookIcon,
	TwitterShareButton,
	TwitterIcon,
	RedditShareButton,
	RedditIcon,
	PocketShareButton,
	PocketIcon,
	EmailShareButton,
	EmailIcon
} from 'react-share'

const SocialSharesWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 40px;

	& > button + button {
		margin-left: 8px;
	}
`

export const SocialShares = (props: { url: string }): ReactElement => {
	return (
		<SocialSharesWrapper>
			<FacebookShareButton url={props.url}>
				<FacebookIcon size={32} />
			</FacebookShareButton>

			<TwitterShareButton url={props.url}>
				<TwitterIcon size={32} />
			</TwitterShareButton>

			<RedditShareButton url={props.url}>
				<RedditIcon size={32} />
			</RedditShareButton>

			<PocketShareButton url={props.url}>
				<PocketIcon size={32} />
			</PocketShareButton>

			<EmailShareButton url={props.url}>
				<EmailIcon size={32} />
			</EmailShareButton>
		</SocialSharesWrapper>
	)
}
