import { ReactElement } from 'react'
import styled from 'styled-components'

import { Anchor } from '~/components/atoms'
import { SiteConfig } from '~/config'

//----- Styling -----//

const InfoWrap = styled.aside`
	font-size: 0.9rem;
`

const Title = styled.div`
	margin-bottom: 0;
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
	text-transform: uppercase;
`

const Content = styled.p`
	margin-top: 0.5em;
	color: ${(p) => p.theme.color.gray[46]};
`

const LinkList = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;

	font-weight: ${(p) => p.theme.font.sans.weight.bold};

	& > li {
		display: inline;
	}

	& > li + li::before {
		content: '|';
		margin: 0 0.5em;
	}
`

const Link = styled(Anchor)`
	color: ${(p) => p.theme.color.gray[26]};
`

//----- Component -----//

export const PrivacyInfo = (): ReactElement => {
	const {
		newsletterChangeEmail,
		newsletterUnsubscribe,
		privacyPolicy
	} = SiteConfig.externalUrls

	return (
		<InfoWrap>
			<Title>Privacy Information</Title>
			<Content>
				At no point have we, or will we, sell our members&apos; e-mail addresses
				to other companies. The only e-mails you&apos;ll receive as a result of
				registering with Discovermagazine.com are occasional breaking news
				alerts from Discovermagazine; any of our e-mail newsletters you sign up
				for above; and occasional surverys and special offers from Kalmbach
				Publishing Co., the producer of Discover magazine and Astronomy
				magazine.
			</Content>
			<LinkList>
				<li>
					<Link to={privacyPolicy}>View Our Privacy Policy</Link>
				</li>
				<li>
					<Link to={newsletterUnsubscribe}>Unsubscribe</Link>
				</li>
				<li>
					<Link to={newsletterChangeEmail}>Change Email Address</Link>
				</li>
			</LinkList>
		</InfoWrap>
	)
}
