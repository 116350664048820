import React, { ReactElement } from 'react'
import Link from 'next/link'
import styled from 'styled-components'

import { Image } from '~/components/atoms'
import { globalAssets } from '~/static/assets'
import { SiteConfig } from '~/config'

//----- Styling -----//

const HomeLink = styled.a`
	display: block;
	position: relative;
	margin: 0 1em;
	height: 100%;
	cursor: pointer;
`

const HeaderImage = styled.div<{ affixed?: boolean; visible: boolean }>`
	${({ affixed }) =>
		affixed &&
		`
		position: absolute;
		top: 50%;
		right: 0;
		left: 0;
		transform: translateY(-50%);
	`}

	max-width: 220px;

	/* max-height: ${({ visible, affixed }) =>
		affixed ? (visible ? '100%' : '64px') : visible ? '64px' : '50px'}; */
	opacity: ${({ visible }) => (visible ? 1 : 0)};

	transition: opacity 450ms ease-in-out, max-height 450ms ease-in-out;

	img {
		display: block;
		max-width: 100%;
		max-height: 100%;
	}
`

//----- Component -----//

interface Props {
	collapsed: boolean
}

export const Logo = (props: Props): ReactElement => {
	const { siteLogo, siteTagline } = globalAssets

	return (
		<Link href="/" passHref>
			<HomeLink>
				<HeaderImage visible={!props.collapsed}>
					<Image
						src={siteLogo}
						alt={SiteConfig.siteInfo.title}
						maxWidth={224}
						maxHeight={64}
					/>
				</HeaderImage>
				<HeaderImage visible={props.collapsed} affixed>
					<Image
						src={siteTagline}
						alt={SiteConfig.siteInfo.tagline}
						maxWidth={226}
						maxHeight={14}
					/>
				</HeaderImage>
			</HomeLink>
		</Link>
	)
}
