import React, { ReactElement } from 'react'
import styled from 'styled-components'

import { ArticleCard_Wide } from '~/components/molecules'
import type { ArticleSummaryData } from '~/types/content'

//----- Styling -----//

const BorderWrapper = styled.div`
	display: grid;
	grid-template-columns: [border] auto [content] 1fr;
	grid-gap: 8px;
	flex-grow: 0;
`

const Border = styled.div`
	width: 10px;
	background-image: linear-gradient(
		to right,
		black,
		black 45%,
		white 45.1%,
		white 55%,
		black 55.1%
	);
`

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 300px;

	& > a:first-of-type {
		padding-top: 8px;
	}

	& > a + a {
		padding-top: 16px;
	}
`

const Title = styled.div`
	text-transform: uppercase;
	font-family: ${(p) => p.theme.font.title.family};
	font-size: 1.5em;
	font-weight: ${(p) => p.theme.font.title.weight.regular};
`

//----- Component -----//

interface ArticleSummaryProps {
	title: string
	articles: ArticleSummaryData[]
}

export function ArticleSummary_Vertical(
	props: ArticleSummaryProps
): ReactElement {
	return (
		<BorderWrapper>
			<Border />
			<ContentWrapper>
				<Title>{props.title}</Title>
				{props.articles?.map((article, i) => (
					<ArticleCard_Wide key={i} article={article} />
				))}
			</ContentWrapper>
		</BorderWrapper>
	)
}
