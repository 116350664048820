import { ComponentProps, FormEvent, useRef, useState } from 'react'
// eslint-disable-next-line import/no-named-as-default
import Router from 'next/router'
import styled from 'styled-components'

import {
	Button_Solid,
	LoadingSpinner_Ring,
	ResultMessage
} from '~/components/atoms'
import { useAppContext, usePageContext } from '~/contexts'
import { InputBlock } from '~/components/molecules'
import { ApiClient, validate } from '~/util'

//----- Constants -----//

type ResultMessageProps = ComponentProps<typeof ResultMessage>

const invalidMsg: ResultMessageProps = {
	text: 'Please enter an access code',
	variant: 'warn'
}

const failedMsg: ResultMessageProps = {
	text: 'An error has occurred. Please review your access code and try again.',
	variant: 'fail'
}

//----- Styling -----//

const StyledButton = styled(Button_Solid)`
	margin-bottom: 0.5em;
	width: 100%;
`

//----- Component -----//

export const AccessCodeForm: React.FC = () => {
	const app = useAppContext()
	const page = usePageContext()
	const accessCodeRef = useRef<HTMLInputElement>(null)
	const [hasInvalidErr, showInvalidErr] = useState(false)
	const [hasFailedErr, showFailedErr] = useState(false)
	const [isLoading, setLoading] = useState(false)

	function validateAccessCode(): boolean {
		const isValid = validate.nonEmptyString(accessCodeRef.current?.value)
		showInvalidErr(!isValid)
		return isValid
	}

	async function handleSubmit(
		event: FormEvent<HTMLFormElement>
	): Promise<void> {
		event.preventDefault()

		const codeValue = accessCodeRef.current?.value

		// Clear any prior errors
		showInvalidErr(false)
		showFailedErr(false)

		// Validate user input
		if (!validateAccessCode() || !codeValue) return

		// Set loading status & attempt to submit access code
		setLoading(true)
		const success = await ApiClient.redeemAccessCode(codeValue)

		setLoading(false)

		if (success) {
			app.setAccessCodeModalOpen(false)
			Router.replace(page.path || '/')
		} else {
			showFailedErr(true)
		}
	}

	return (
		<form onSubmit={handleSubmit}>
			<InputBlock
				caption="Access Code"
				message={hasInvalidErr ? invalidMsg : { variant: 'empty' }}
				input={{
					onBlur: validateAccessCode,
					ref: accessCodeRef,
					tall: true,
					fullWidth: true
				}}
			/>
			<StyledButton type="submit">
				Submit
				{isLoading && <LoadingSpinner_Ring variant="base" size={14} floating />}
			</StyledButton>
			<ResultMessage
				text={hasFailedErr ? failedMsg.text : ''}
				variant={hasFailedErr ? failedMsg.variant : 'empty'}
				center
				big
			/>
		</form>
	)
}
