import { ReactElement } from 'react'
import styled from 'styled-components'

import { Anchor } from '~/components/atoms'
import type { RichTextContent } from '~/types/content'
import { ContentAsReact, useRichTextContext } from '../util'

//----- Styling -----//

const AnchorNode = styled(Anchor)`
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
	color: ${(p) => p.theme.color.primary.accent};
`

//----- Component -----//

export const Hyperlink = (props: RichTextContent): ReactElement | null => {
	if (!props.data.uri) return null
	const richText = useRichTextContext()

	const attrs: string[] = []
	const [linkTarget, linkAttrs] = props.data.uri.split('|')

	if (linkAttrs) linkAttrs.split(',').forEach((a) => attrs.push(a))

	return (
		<AnchorNode
			to={linkTarget}
			sponsored={richText.template === 'sponsored'}
			nofollow={attrs.includes('nofollow')}
		>
			<ContentAsReact content={props.content} />
		</AnchorNode>
	)
}
