import { ReactElement } from 'react'

import type { RichTextContent } from '~/types/content'
import { ContentAsReact } from '../util'

//----- Component -----//

export const Heading4 = (props: RichTextContent): ReactElement => (
	<h4>
		<ContentAsReact content={props.content} />
	</h4>
)
