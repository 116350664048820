import { ReactElement } from 'react'
import styled from 'styled-components'

import { ArrowBack } from 'styled-icons/material/ArrowBack'
import { ArrowForward } from 'styled-icons/material/ArrowForward'
import { ChevronLeft } from 'styled-icons/material/ChevronLeft'
import { ChevronRight } from 'styled-icons/material/ChevronRight'

//----- Styling -----//

const IconWrap = styled.div`
	display: inline-block;

	svg {
		color: ${(p) => p.theme.color.gray[74]};
		width: 32px;
	}
`

//----- Component -----//

interface NavIconProps {
	className?: string
	back?: boolean
	gallery?: boolean
}

export const NavIcon = (props: NavIconProps): ReactElement => {
	// Determine which style of icon to use
	const BackIcon = props.gallery ? ChevronLeft : ArrowBack
	const NextIcon = props.gallery ? ChevronRight : ArrowForward
	// Determine which direction the icon should point
	const Icon = props.back ? BackIcon : NextIcon

	return (
		<IconWrap className={props.className}>
			<Icon />
		</IconWrap>
	)
}
