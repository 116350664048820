import { ReactElement } from 'react'
import styled from 'styled-components'

import { Anchor } from '~/components/atoms'
import {
	ArticleData,
	AuthorData,
	BlogData,
	CategoryData,
	CustomPageData,
	DepartmentData,
	IssueData,
	RichTextContent,
	TagData
} from '~/types/content'
import { getPath } from '~/util'

import { ContentAsReact } from '../util'

//----- Styling -----//

const StyledAnchor = styled(Anchor)`
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
	color: ${(p) => p.theme.color.primary.accent};
`

//----- Util -----//

function getLinkUrl(
	type: string,
	target: RichTextContent['data']['target']
): string | null {
	if (!target) return null

	let fields

	switch (type) {
		case 'article':
			fields = target.fields as ArticleData
			return getPath.article(fields.slug, fields.refs?.category?.slug)
		case 'author':
			fields = target.fields as AuthorData
			return getPath.author(fields.slug)
		case 'blog':
			fields = target.fields as BlogData
			return getPath.blog(fields.slug)
		case 'category':
			fields = target.fields as CategoryData
			return getPath.category(fields.slug)
		case 'customPage':
			fields = target.fields as CustomPageData
			return getPath.customPage(fields.slug)
		case 'department':
			fields = target.fields as DepartmentData
			return getPath.department(fields.slug)
		case 'issue':
			fields = target.fields as IssueData
			return getPath.issue(fields.slug, fields.publication.date)
		case 'tag':
			fields = target.fields as TagData
			return getPath.tag(fields.slug)
		default:
			return null
	}
}

//----- Component -----//

export const EntryHyperlink = (props: RichTextContent): ReactElement | null => {
	if (props.data.target?.sys.type !== 'Entry') return null

	const targetType = props.data.target?.sys.contentType?.sys.id
	if (!targetType) return null

	const link = getLinkUrl(targetType, props.data.target)
	if (!link) return null

	return (
		<StyledAnchor to={link}>
			<ContentAsReact content={props.content} />
		</StyledAnchor>
	)
}
