import { useEffect } from 'react'
import { NextPage } from 'next'

import { Container, PageSubtitle, UserHydrator } from '~/components/atoms'
import { ArticleGrid } from '~/components/organisms'
import { StandardTemplate } from '~/components/templates'
import { PageProvider } from '~/contexts'
import type { MagazinePageProps } from '~/types/page'
import { Analytics, getPath, setAdTargets } from '~/util'

import { YearSelect } from './atoms'
import { IssueCarousel, MagazineBottom, MagazineTop } from './organisms'

export const MagazinePage: NextPage<MagazinePageProps> = (props) => {
	const { content, page, user } = props

	const { active, articles, issues, products, years } = content
	const activeIssue = issues[active.issueIndex]

	// NextMillennium ad targeting code
	useEffect(() => setAdTargets(page.path, null), [])

	useEffect(() => {
		Analytics.getInstance()
			.setLoginStatus(user?.email, user?.isLoggedIn, user?.hasMagazineSub)
			.setCategory(null)
			.pageview(getPath.issue(activeIssue.slug, activeIssue.publication.date))
	})

	return (
		<UserHydrator user={user}>
			<PageProvider pageConfig={page}>
				<StandardTemplate
					top={<MagazineTop />}
					bottom={<MagazineBottom products={products} />}
				>
					<Container>
						<YearSelect years={years} activeYear={active.year} />
						{/*
						We use a key here to force the activeIssue position
						to reset when the issues change.
						*/}
						<IssueCarousel
							issues={issues}
							activeIssue={active.issueIndex}
							key={`${active.year}` || 0}
						/>
						<PageSubtitle style={{ fontWeight: 'bold' }}>
							{activeIssue?.publication?.period}, {active.year}
						</PageSubtitle>
						<ArticleGrid articles={articles} wideThumbs />
					</Container>
				</StandardTemplate>
			</PageProvider>
		</UserHydrator>
	)
}
