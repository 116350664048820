import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'

import { SiteConfig } from '~/config'
import { usePageContext } from '~/contexts'
import { Analytics } from '~/util'

//----- Styling -----//

const Link = styled.a.attrs((props) => ({
	bgColor: props.theme.color.secondary.base,
	elHeight: 29
}))`
	position: relative;
	display: block;
	margin: 1.2em auto 0;
	padding: 0.6em 0 0.5em;
	height: ${(p) => p.elHeight}px;
	width: 100px;

	color: ${(p) => p.theme.color.primary.contrast};
	font-family: ${(p) => p.theme.font.title.family};
	font-size: 0.875em;
	line-height: 1;
	letter-spacing: ${(p) => p.theme.font.title.spacing.wide};
	text-align: center;
	text-transform: uppercase;

	background: ${(p) => p.bgColor};
	transition: padding 100ms ease-in-out;

	&::before {
		content: '>';
		position: absolute;
		box-sizing: border-box;
		top: 0;
		left: 0;
		padding: 0.35em;
		height: ${(p) => p.elHeight}px;

		font-size: 1.1em;
		font-family: ${(p) => p.theme.font.sans.family};
		font-weight: ${(p) => p.theme.font.sans.weight.bold};

		border-radius: 0 50% 50% 0;
		background: ${(p) => lighten(0.1, p.bgColor)};
		transform: scaleX(0);
		transition: transform 100ms ease-in-out;
		transform-origin: left center;
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: -10px;
		bottom: 0;

		border: 15px solid transparent;
		border-left: 10px solid ${(p) => p.bgColor};
		border-right: 0;
	}

	&:hover {
		text-decoration: none;
		padding-left: 1.5em;

		&::before {
			transform: scaleX(1);
		}
	}

	@media (min-width: ${(p) => p.theme.responsive.breakpoints.md}) {
		margin: 0.6em 0 0;
	}
`

//----- Component -----//

export const IssueSubscribe = (): ReactElement => {
	function handleClick() {
		Analytics.getInstance().event({
			category: 'Page Subscriber Click',
			action: 'Subscription Click',
			label: usePageContext().path
		})
	}

	return (
		<Link
			href={SiteConfig.externalUrls.subscribe}
			onClick={handleClick}
			target="_blank"
			rel="noopener noreferrer"
		>
			Subscribe
		</Link>
	)
}
