import { ReactElement } from 'react'
import styled from 'styled-components'

import type { RichTextContent } from '~/types/content'
import { ContentAsReact } from '../util'

//----- Styling -----//

const H1 = styled.h1`
	font-family: ${(p) => p.theme.font.serif.family};
	font-weight: ${(p) => p.theme.font.serif.weight.regular};
	font-size: 3em;
`

//----- Component -----//

export const Heading1 = (props: RichTextContent): ReactElement => (
	<H1>
		<ContentAsReact content={props.content} />
	</H1>
)
