import { ReactElement } from 'react'
import Head from 'next/head'

import type { AuthorData } from '~/types/content'

//----- Component -----//

interface StructuredDataProps {
	url: string
	title?: string
	description?: string
	imageUrl?: string
	datePublished?: string
	dateModified?: string
	author?: AuthorData
}

/* Structured Data / CreativeWork schema */
export const StructuredData = (props: StructuredDataProps): ReactElement => (
	<Head>
		<script
			key="structured-data"
			type={'application/ld+json'}
			dangerouslySetInnerHTML={{
				__html: JSON.stringify({
					'@context': 'https://schema.org',
					'@type': 'Article',
					mainEntityOfPage: {
						'@type': 'WebPage',
						'@id': props.url
					},
					headline: props.title,
					image: props.imageUrl,
					datePublished: props.datePublished,
					dateModified: props.dateModified,
					author: props.author?.name
						? {
								'@type': 'Person',
								name: props.author.name
						  }
						: null,
					publisher: {
						name: 'Discover Magazine',
						'@type': 'Organization',
						logo: {
							'@type': 'ImageObject',
							url:
								'https://www.discovermagazine.com/assets/globalAssets/siteLogo.png'
						}
					},
					description: props.description,
					isAccessibleForFree: 'False',
					hasPart: {
						'@type': 'WebPageElement',
						isAccessibleForFree: 'False',
						cssSelector: '.paywall'
					}
				})
			}}
		/>
	</Head>
)
