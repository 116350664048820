import { useEffect, useState } from 'react'
import styled, { css, CSSProperties } from 'styled-components'
import { Close } from 'styled-icons/material/Close'

import { useAppContext } from '~/contexts'

const Wrapper = styled.div<{ inverted?: boolean; fullScreen?: boolean }>`
	position: fixed;
	padding: 2.5em 1em 1em;

	max-height: 100%;
	overflow: hidden auto;

	${(p) =>
		p.fullScreen
			? css`
					top: 0;
					left: 0;
					width: 100vw;
					height: 100vh;
			  `
			: css`
					width: 100vw;
					max-width: 440px;

					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
			  `}

	color: ${(p) =>
		p.inverted ? p.theme.color.primary.base : p.theme.color.primary.contrast};
	background: ${(p) =>
		p.inverted ? p.theme.color.primary.contrast : p.theme.color.primary.base};
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
	${(p) => (p.inverted ? `border: 1px solid ${p.theme.color.gray[67]};` : '')}
	border-bottom: 8px solid ${(p) => p.theme.color.primary.accent};

	z-index: ${(p) => p.theme.misc.zIndex.modal};

	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	@keyframes fadeOut {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}

	@media (min-width: ${(p) => p.theme.responsive.breakpoints.sm}) {
		padding: 2.5em 2em 2em;
	}
`

const IconWrap = styled.div`
	position: absolute;
	top: 0.5em;
	right: 0.5em;
	padding: 0.25em;

	cursor: pointer;

	svg {
		width: 24px;
	}
`

export const Modal: React.FC<{
	isOpen: boolean
	unclosable?: boolean
	inverted?: boolean
	fullScreen?: boolean
	style?: CSSProperties
	onOpenFinish?: () => void
}> = ({
	children,
	isOpen,
	unclosable,
	inverted,
	fullScreen,
	style,
	onOpenFinish
}) => {
	const [shouldRender, setShouldRender] = useState(isOpen)
	const app = useAppContext()

	// Enable element render before fadein
	useEffect(() => {
		isOpen && setShouldRender(true)
	}, [isOpen])

	// Disable element render after fadeout
	const onAnimationEnd = () => {
		if (!isOpen) setShouldRender(false)
		if (isOpen && onOpenFinish) onOpenFinish()
	}

	if (!shouldRender) return null

	return (
		<Wrapper
			inverted={inverted}
			fullScreen={fullScreen}
			onAnimationEnd={onAnimationEnd}
			style={{
				...style,
				animation: `${isOpen ? 'fadeIn' : 'fadeOut'} 200ms`
			}}
		>
			{!unclosable && (
				<IconWrap onClick={app.closeAllDialogs}>
					<Close aria-label="Close Button" />
				</IconWrap>
			)}
			{children}
		</Wrapper>
	)
}
