import { ReactElement, RefObject } from 'react'
import styled from 'styled-components'

import type { RichTextContent } from '~/types/content'

import { ContentAsReact, RichTextProvider } from './util'

//----- Styling -----//

const Content = styled.article`
	line-height: 24px;
`

//----- Component -----//

export const RichText = (props: {
	content?: RichTextContent | string
	template?: string
	contentRef?: RefObject<HTMLDivElement>
}): ReactElement | null => {
	if (!props.content) return null
	if (typeof props.content === 'string')
		return <Content>{props.content}</Content>

	return (
		<RichTextProvider value={{ template: props.template }}>
			<Content ref={props.contentRef}>
				<ContentAsReact content={[props.content]} />
			</Content>
		</RichTextProvider>
	)
}
