import { useEffect } from 'react'
import { NextPage } from 'next'

import {
	AdUnit,
	BlockDescriptor,
	Container,
	UserHydrator,
	Whitespace
} from '~/components/atoms'
import {
	ArticleGrid,
	IssueCallout,
	ProductArea,
	PromoArea,
	TopStories
} from '~/components/organisms'
import { StandardTemplate } from '~/components/templates'
import { PageProvider } from '~/contexts'
import type { ArticleSummaryData, PromoAreaData } from '~/types/content'
import type { HomePageProps } from '~/types/page'
import { Analytics, setAdTargets } from '~/util'

//----- Util -----//

type ArticleGroups = {
	topStories?: ArticleSummaryData[]
	grid1?: ArticleSummaryData[]
	grid2?: ArticleSummaryData[]
	grid3?: ArticleSummaryData[]
}

/**
 * Splits articles into appropriate groups for the page
 */
function getArticleGroups(articles: ArticleSummaryData[] = []): ArticleGroups {
	// Clone the array to ensure changes do not impact the original array
	const allArticles = [...articles]

	return {
		topStories: allArticles.splice(0, 5),
		grid1: allArticles.splice(0, 12),
		grid2: allArticles.splice(0, 12),
		grid3: allArticles.splice(0, 12)
	}
}

type PromoAreaRegions = {
	region1?: PromoAreaData
	region2?: PromoAreaData
}

/**
 * Converts PromoArea array into named object based on locationId
 */
function getPromoAreas(promoAreas?: PromoAreaData[]): PromoAreaRegions {
	if (!promoAreas) return {}

	const regions: PromoAreaRegions = {}

	promoAreas.forEach((area) => {
		if (area.locationId === 'home/region-1') regions.region1 = area
		if (area.locationId === 'home/region-2') regions.region2 = area
	})

	return regions
}

//----- Component -----//

export const HomePage: NextPage<HomePageProps> = (props) => {
	const { page, content, user } = props

	const articleGroups = getArticleGroups(content?.articles)
	const promoAreas = getPromoAreas(content?.promoAreas)

	// NextMillennium ad targeting code
	useEffect(() => setAdTargets(page.path, null), [])

	useEffect(() => {
		Analytics.getInstance()
			.setLoginStatus(user?.email, user?.isLoggedIn, user?.hasMagazineSub)
			.setCategory(null)
			.pageview('/')
	}, [])

	return (
		<UserHydrator user={user}>
			<PageProvider pageConfig={page}>
				<StandardTemplate>
					<Container>
						<TopStories articles={articleGroups.topStories} />
					</Container>

					<Whitespace xs={'4em'} />
					<AdUnit position="homepageBillboardDesktop" showBg hideBelow={980} />
					<AdUnit position="homepageBillboardMobile" showBg hideAbove={981} />
					<Whitespace xs={'3em'} />

					<Container>
						<ArticleGrid articles={articleGroups.grid1} />
					</Container>

					{content?.issue && (
						<>
							<Whitespace xs={'3em'} />
							<IssueCallout
								issue={content?.issue}
								articles={content?.issueArticles}
							/>
						</>
					)}

					<Container>
						<Whitespace xs={'4em'} />
						<PromoArea cards={promoAreas.region1?.cards} />
						<Whitespace xs={'1em'} />

						<BlockDescriptor marginTop marginBottom>
							More From Discover
						</BlockDescriptor>
						<ArticleGrid articles={articleGroups.grid2} />

						<Whitespace xs={'3em'} />
						<PromoArea cards={promoAreas.region2?.cards} />
					</Container>

					<Whitespace xs={'2em'} />
					<AdUnit position="homepageBottomDesktop" showBg hideBelow={980} />
					<AdUnit position="homepageBottomMobile" showBg hideAbove={981} />
					<Whitespace xs={'3em'} />

					<Container>
						<ProductArea products={content?.products} />
						<BlockDescriptor marginBottom>Lifestyle</BlockDescriptor>
						<ArticleGrid articles={content?.lifestyleArticles} />
					</Container>
				</StandardTemplate>
			</PageProvider>
		</UserHydrator>
	)
}
