import { ReactElement } from 'react'

import { Anchor } from '~/components/atoms'
import type { AuthorData } from '~/types/content'
import { getPath } from '~/util'

export const Author = (props: {
	i: number
	total: number
	author: AuthorData
}): ReactElement | null => {
	if (!props.author) return null

	const isOneOfMany = props.total > 2
	const isFirst = props.i === 0
	const isLast = props.i === props.total - 1

	return (
		<>
			{isOneOfMany && !isFirst ? ', ' : ''}
			{isLast && !isFirst ? ' and ' : ''}
			<Anchor to={getPath.author(props.author.slug)}>
				{props.author.name}
			</Anchor>
		</>
	)
}
