import { ReactElement } from 'react'

import type { RichTextContent } from '~/types/content'
import { ContentAsReact } from '../util'

//----- Component -----//

export const Heading6 = (props: RichTextContent): ReactElement => (
	<h6>
		<ContentAsReact content={props.content} />
	</h6>
)
