import { ReactElement } from 'react'
import styled from 'styled-components'

import { Container, PageSubtitle, PageTitle } from '~/components/atoms'
import { ByLine } from '~/components/molecules'
import type { CategoryData, BlogData, AuthorData } from '~/types/content'
import { CategoryLink } from '../atoms'

//----- Styling -----//

const StyledContainer = styled(Container)`
	&::after {
		content: '';
		display: block;
		margin: 1em 0 2em;
		width: 2em;
		border-bottom: 5px double ${(p) => p.theme.color.primary.contrast};
	}
`

//----- Component -----//

interface ArticleTopProps {
	authors?: AuthorData[]
	blog?: BlogData
	category?: CategoryData
	publicationDate?: string
	subtitle?: string
	title?: string
}

export const ArticleTop = (props: ArticleTopProps): ReactElement => (
	<StyledContainer>
		<CategoryLink category={props.category} />
		<PageTitle smallMargins retainCapitalization>
			{props.title || 'Article Title'}
		</PageTitle>
		<PageSubtitle>{props.subtitle}</PageSubtitle>
		<ByLine
			authors={props.authors}
			blog={props.blog}
			publicationDate={props.publicationDate}
		/>
	</StyledContainer>
)
