import { ReactElement } from 'react'

import type { RichTextContent } from '~/types/content'
import { ContentAsReact } from '../util'

//----- Component -----//

export const Heading5 = (props: RichTextContent): ReactElement => (
	<h5>
		<ContentAsReact content={props.content} />
	</h5>
)
