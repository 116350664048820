import { Block } from 'styled-icons/material/Block'
import { Description } from 'styled-icons/material/Description'
import { Lock } from 'styled-icons/material/Lock'
import styled from 'styled-components'

import { Button_Solid } from '~/components/atoms'
import type { IssueData } from '~/types/content'
import { useAppContext } from '~/contexts'
import { Analytics } from '~/util'

//----- Styling -----//

const Button = styled(Button_Solid)`
	font-size: 0.8em;
	padding-bottom: 0.15em;
`

const IconWrap = styled.span`
	display: inline-block;
	margin-right: 0.25em;
	transform: translateY(-2px);
`

//----- Component -----//

export const ReadIssue: React.FC<{
	archive: IssueData['archive']
}> = ({ archive }) => {
	const { openReaderModal } = useAppContext()

	if (!archive.userIsEntitled) {
		// TODO: Link to subscribe/info page?
		return (
			<Button disabled>
				<IconWrap>
					<Lock width={18} />
				</IconWrap>
				Subscribe
			</Button>
		)
	}

	if (!archive.downloadPath) {
		return (
			<Button disabled>
				<IconWrap>
					<Block width={18} />
				</IconWrap>
				Unavailable
			</Button>
		)
	}

	function showModal() {
		if (!archive.downloadPath) return

		Analytics.getInstance().archiveRead(archive.downloadPath)
		openReaderModal(archive.downloadPath)
	}

	return (
		<>
			<Button onClick={showModal}>
				<IconWrap>
					<Description width={18} />
				</IconWrap>
				read issue
			</Button>
		</>
	)
}
