import { ReactElement } from 'react'

import { StickySidebar } from '~/components/atoms'
import { ArticleSummary_Vertical } from '~/components/organisms'
import type { ArticleSummaryData } from '~/types/content'

export const ArticleRight = (props: {
	articles?: ArticleSummaryData[]
}): ReactElement => (
	<StickySidebar>
		<ArticleSummary_Vertical
			title={'Related Content'}
			articles={props.articles || []}
		/>
	</StickySidebar>
)
