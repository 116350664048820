import styled from 'styled-components'

//----- Types -----//

interface ComponentProps {
	// applyTo can specify a child node to apply styling to
	// instead of applying it directly to the anchor
	applyTo?: string
	inverted?: boolean
}

interface DerivedProps {
	baseColor: string
	activeColor: string
	effectTarget: string
}

//----- Component -----//

export const HeaderAnchor = styled.a.attrs<ComponentProps, DerivedProps>(
	(p) => ({
		baseColor: p.inverted ? p.theme.color.gray[46] : p.theme.color.gray[13],
		activeColor: p.inverted ? p.theme.color.gray[13] : p.theme.color.gray[46],
		effectTarget: p.applyTo ? `& > ${p.applyTo}` : '&'
	})
)<ComponentProps>`
	display: inline-block;
	cursor: pointer;

	${(p) => p.effectTarget} {
		color: ${(p) => p.baseColor};

		text-transform: uppercase;
		line-height: 1;

		border-top: 0.1em solid transparent;
		border-bottom: 0.1em solid transparent;
		transition: color 300ms ease-in-out, border 300ms ease-in-out;
	}

	&:hover,
	&:active,
	&:focus {
		text-decoration: none;

		${(p) => p.effectTarget} {
			color: ${(p) => p.activeColor};
			border-bottom-color: ${(p) => p.activeColor};
		}
	}
`
