import { ReactElement, useState } from 'react'
import styled from 'styled-components'
import { ExpandLess } from 'styled-icons/material/ExpandLess'
import { ExpandMore } from 'styled-icons/material/ExpandMore'

import { Button_CalloutLink } from '~/components/atoms'
import { SiteConfig } from '~/config'
import { usePaywallContext } from '~/contexts'

import { SlideoutArea } from '../SlideoutArea'

//----- Styling -----//

const Banner = styled.div`
	@media (min-width: ${(p) => p.theme.responsive.breakpoints.lg}) {
		display: none;
	}
`

const UpperArea = styled.div`
	text-align: center;
	padding: 2.4em 0.5em;
`

const Remaining = styled.span`
	display: inline-block;
	margin-right: 0.75em;

	font-size: 1.1em;
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
`

const ToggleButton = styled.button`
	background: transparent;
	border: 0;
	outline: 0;

	color: ${(p) => p.theme.color.primary.base};
`

//----- Component -----//

export const SmallCountdown = (): ReactElement | null => {
	const [expanded, setExpanded] = useState(false)
	const paywall = usePaywallContext()
	if (paywall.isEntitled) return null

	const subscribeUrl = SiteConfig.externalUrls.subscribe
	const articlePlural = `article${paywall.viewsRemaining === 1 ? '' : 's'}`

	return (
		<Banner>
			<UpperArea>
				<Remaining>
					{paywall.viewsRemaining} free {articlePlural}
				</Remaining>
				<Button_CalloutLink extraSlim inverted invertedBorder to={subscribeUrl}>
					Subscribe
				</Button_CalloutLink>
				<ToggleButton
					onClick={() => setExpanded(!expanded)}
					aria-label={`${expanded ? 'Hide' : 'Show'} subscription benefits`}
				>
					{expanded ? <ExpandMore width={20} /> : <ExpandLess width={20} />}
				</ToggleButton>
			</UpperArea>
			<SlideoutArea expanded={expanded} />
		</Banner>
	)
}
