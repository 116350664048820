import styled from 'styled-components'

import { Image } from '~/components/atoms'

//----- Constants -----//

const imageSize = { maxWidth: 125, maxHeight: 45 }

//----- Styling -----//

const List = styled.ul`
	padding: 0;
	margin-bottom: 0;
	display: flex;
	flex-wrap: wrap;
	justify-items: center;
`

const Item = styled.li`
	display: inline-block;
	flex-grow: 1;
	text-align: center;
`

//----- Component -----//

export const PartnerList: React.FC<{
	images: { src: string; alt: string }[]
}> = (props) => {
	return (
		<List>
			{props.images.map((image, i) => (
				<Item key={i}>
					<Image {...image} {...imageSize} />
				</Item>
			))}
		</List>
	)
}
