import { ReactElement } from 'react'
import styled from 'styled-components'

import { globalAssets } from '~/static/assets'

//----- Styling -----//

const GalleryIcon = styled.img`
	position: absolute;
	top: 4px;
	left: 4px;
`

//----- Component -----//

interface GalleryTagProps {
	template?: string
}

export const GalleryTag = (props: GalleryTagProps): ReactElement | null => {
	if (props.template !== 'Gallery') return null

	return <GalleryIcon src={globalAssets.camera} alt="Gallery Article" />
}
