import { NextPage } from 'next'
import { useEffect } from 'react'

import { PageTitle, StickySidebar, UserHydrator } from '~/components/atoms'
import { RichText } from '~/components/organisms'
import { ErrorPage } from '~/components/pages'
import { StandardTemplate } from '~/components/templates'
import { PageProvider } from '~/contexts'
import type { CustomPageProps } from '~/types/page'
import { Analytics, getPath, setAdTargets } from '~/util'

import { NewsletterSignup } from './organisms'

export const CustomPage: NextPage<CustomPageProps> = (props) => {
	const { page, user, content } = props

	if ('errorStatus' in props) return <ErrorPage {...props} />
	if (!content?.page || content?.type !== 'page')
		return <ErrorPage errorStatus={500} />

	// NextMillennium ad targeting code
	useEffect(() => setAdTargets(page.path, null), [])

	useEffect(() => {
		Analytics.getInstance()
			.setLoginStatus(user?.email, user?.isLoggedIn, user?.hasMagazineSub)
			.setCategory(null)
			.pageview(getPath.customPage(content.page.slug))
	}, [])

	return (
		<UserHydrator user={user}>
			<PageProvider pageConfig={page}>
				<StandardTemplate
					right={
						<StickySidebar>
							<></>
						</StickySidebar>
					}
					useContainer
				>
					<PageTitle>{content.page.title}</PageTitle>
					<RichText content={content.page.body} />
					{content.page.template === 'Newsletter' && <NewsletterSignup />}
				</StandardTemplate>
			</PageProvider>
		</UserHydrator>
	)
}
