import { Fragment, ReactElement } from 'react'
import LazyLoad from 'react-lazyload'
import styled, { css } from 'styled-components'

import { AdUnit } from '~/components/atoms'
import { ArticleCard } from '~/components/molecules'
import { SiteConfig } from '~/config'
import type { ArticleSummaryData } from '~/types/content'

//----- Styling -----//

const GridContainer = styled.div<{ wideThumbs?: boolean }>`
	display: grid;
	/* grid-template-columns: repeat(auto-fill, minmax(12.5rem, 1fr)); */
	grid-template-columns: repeat(auto-fill, minmax(12.5rem, 1fr));
	grid-gap: 1rem;

	${(p) =>
		p.wideThumbs &&
		css`
			@media (min-width: 711px) {
				grid-template-columns: repeat(auto-fill, minmax(15.5rem, 1fr));
			}
		`}
`

const AdSlot = styled.div`
	grid-column: 1/-1;
`

//----- Component -----//

interface ArticleGridBaseProps {
	articles?: ArticleSummaryData[]
	wideThumbs?: boolean
}

interface ArticleGridNoAds extends ArticleGridBaseProps {
	showAdAfter?: never
	adUnitName?: never
}

interface ArticleGridAds extends ArticleGridBaseProps {
	showAdAfter: number
	adUnitName: keyof typeof SiteConfig.ads.unit
}

export const ArticleGrid = (
	props: ArticleGridNoAds | ArticleGridAds
): ReactElement => {
	return (
		<GridContainer wideThumbs={props.wideThumbs}>
			{props.articles &&
				props.articles.map((article, i) => (
					<Fragment key={i}>
						<ArticleCard article={article} />
						{props.adUnitName && props.showAdAfter === i + 1 && (
							<AdSlot>
								<LazyLoad unmountIfInvisible>
									<AdUnit position={props.adUnitName} showBg />
								</LazyLoad>
							</AdSlot>
						)}
					</Fragment>
				))}
		</GridContainer>
	)
}
