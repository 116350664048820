import { ReactElement } from 'react'
import Router from 'next/router'
import styled from 'styled-components'

import { Button_CalloutLink, Image } from '~/components/atoms'
import { SiteConfig } from '~/config'
import { useAppContext, usePageContext, useUserContext } from '~/contexts'
import { ApiClient } from '~/util'

//----- Styling -----//

const FooterWrap = styled.div`
	margin: 2.5em 0;

	text-align: center;
`

const ButtonWrap = styled.div`
	padding-top: 1.2em;

	font-size: 0.9em;
`

//----- Component -----//

export const SlideoutFooter = (): ReactElement => {
	const app = useAppContext()
	const page = usePageContext()
	const user = useUserContext()

	async function attemptLogout(): Promise<void> {
		await ApiClient.logout()
		user.reset()
		Router.replace(page.path || '/')
	}

	const showLoginModal = () => {
		app.toggleSlideoutMenu()
		app.setLoginModalOpen(true)
	}

	const button = user.isLoggedIn
		? { action: attemptLogout, text: 'Logout' }
		: { action: showLoginModal, text: 'Login' }

	return (
		<FooterWrap>
			<Image
				src="/assets/globalAssets/slideoutFooterSplash.png"
				alt={SiteConfig.siteInfo.splashText}
				maxWidth={129}
			/>
			<ButtonWrap>
				<Button_CalloutLink inverted slim onClick={button.action}>
					{button.text}
				</Button_CalloutLink>
			</ButtonWrap>
		</FooterWrap>
	)
}
