import React, { ReactElement } from 'react'
import Router from 'next/router'
import Link from 'next/link'
import styled from 'styled-components'
import { Email } from 'styled-icons/material/Email'

import {
	Button_CalloutLink,
	List_Inline,
	ListItem_Inline
} from '~/components/atoms'
import {
	useAppContext,
	usePageContext,
	useThemeContext,
	useUserContext
} from '~/contexts'
import { SiteConfig } from '~/config'
import { ApiClient, getPath } from '~/util'

import { HeaderAnchor } from '../atoms'

//----- Styling -----//

const UserPanelList = styled(List_Inline)`
	font-size: 0.75em;
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
`

const NewsletterAnchor = styled(HeaderAnchor)`
	display: flex;
	align-items: center;
`

//----- Component -----//

const EmailIcon = () => {
	const { color } = useThemeContext()
	const fill = color.primary.base
	const stroke = color.primary.accent

	return (
		<Email
			fill={fill}
			stroke={stroke}
			size={24}
			style={{
				marginRight: 4,
				marginTop: 1,
				flexShrink: 0
			}}
		/>
	)
}

export const UserPanel = (props: { isCollapsed: boolean }): ReactElement => {
	const app = useAppContext()
	const page = usePageContext()
	const user = useUserContext()

	async function attemptLogout(): Promise<void> {
		await ApiClient.logout()
		user.reset()
		Router.replace(page.path || '/')
	}

	return (
		<UserPanelList gap={1.8} align="right">
			<ListItem_Inline hideOnMobile>
				{user.isLoggedIn ? (
					<HeaderAnchor onClick={attemptLogout}>Logout</HeaderAnchor>
				) : (
					<HeaderAnchor onClick={app.toggleLoginModal}>Login</HeaderAnchor>
				)}
			</ListItem_Inline>

			{user.isLoggedIn ? null : (
				<ListItem_Inline hideOnMobile>
					<HeaderAnchor onClick={app.toggleRegisterModal}>
						Register
					</HeaderAnchor>
				</ListItem_Inline>
			)}

			<ListItem_Inline hideOnMobile>
				<Link href={getPath.customPage('newsletter-signup')} passHref>
					<NewsletterAnchor applyTo="span">
						<EmailIcon />
						<span>Stay Curious</span>
					</NewsletterAnchor>
				</Link>
			</ListItem_Inline>

			<ListItem_Inline>
				<Button_CalloutLink
					slim={props.isCollapsed}
					to={SiteConfig.externalUrls.subscribe}
				>
					{user.hasMagazineSub ? 'Renew' : 'Subscribe'}
				</Button_CalloutLink>
			</ListItem_Inline>
		</UserPanelList>
	)
}
