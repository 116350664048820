import React from 'react'
import styled from 'styled-components'
import { Close } from 'styled-icons/material/Close'

import { useAppContext } from '~/contexts'

//----- Styling -----//

const CloseWrap = styled.div`
	padding: 16px;
	text-align: right;
`

const CloseButton = styled.button`
	padding: 0;
	width: 24px;

	color: ${({ theme }) => theme.color.primary.base};
	background: transparent;
	border: 0;
	cursor: pointer;
`

//----- Component -----//

export const SlideoutHeader = () => {
	const { toggleSlideoutMenu } = useAppContext()

	return (
		<CloseWrap>
			<CloseButton onClick={toggleSlideoutMenu} aria-label="Close Sidebar">
				<Close />
			</CloseButton>
		</CloseWrap>
	)
}
