import { ReactElement } from 'react'

import { HorizontalRule as HR } from '~/components/atoms'
import type { RichTextContent } from '~/types/content'

//----- Component -----//

export const HorizontalRule = (props: RichTextContent): ReactElement => {
	return <HR />
}
